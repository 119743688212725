import * as React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Contact from "../components/Contact"

const ContatoPage = ({ transitionStatus, entry }) => (
  <Layout transitionStatus={transitionStatus} entry={entry}>
    <SEO title="Contato" />
    <Contact transitionStatus={transitionStatus} />
  </Layout>
)

export default ContatoPage
