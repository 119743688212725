import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Instagram } from "@styled-icons/remix-line/Instagram"
import L from "leaflet"

import gsap from "gsap"

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"

import markerIcon from "../../images/logo.png"

const Contact = ({ transitionStatus }) => {
  const position = [-24.295564098166714, -46.96765319742401]

  const [icon, setIcon] = useState({})

  const messageRef = useRef(null)
  const mapRef = useRef(null)
  const dataRef = useRef([])
  dataRef.current = []

  useEffect(() => {
    //DEFININDO ICONE
    const iconPerson = new L.Icon({
      iconUrl: markerIcon,
      iconSize: [40, 17.24],
    })
    setIcon(iconPerson)

    //ANIMAÇÂO

    const tl = gsap.timeline()

    tl.from(messageRef.current, {
      y: 100,
      opacity: 0,
    })
      .from(mapRef.current, {
        width: 0,
      })
      .from(dataRef.current, {
        opacity: 0,
        y: 100,
        stagger: 0.1,
      })
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      const tl = gsap.timeline()

      tl.to(messageRef.current, {
        y: -100,
        opacity: 0,
      })
        .to(
          mapRef.current,
          {
            width: 0,
          },
          "<"
        )
        .to(
          dataRef.current,
          {
            opacity: 0,
            y: -100,
            stagger: 0.1,
          },
          "-=0.6"
        )
    }
  }, [transitionStatus])

  const addRef = el => {
    if (el && !dataRef.current.includes(el)) {
      dataRef.current.push(el)
    }
  }

  return (
    <Container>
      <Wrapper>
        <TextSection>
          <PageTitle ref={messageRef}>Diga olá!</PageTitle>
          <InformationArea>
            <InformationLine>
              <InformationItem ref={addRef}>
                <h3>Telefone</h3>
                <a href="callto:551334582196">+55 (13) 3458-2196</a>
              </InformationItem>
              <InformationItem ref={addRef}>
                <h3>Endereço</h3>
                <p>
                  Endereço: O endereço é Av. Padre Anchieta, <br />
                  5086. Loja 2. Jd. Marcia. Peruíbe - SP
                </p>
              </InformationItem>
            </InformationLine>
            <InformationLine>
              <InformationItem ref={addRef}>
                <h3>E-mail</h3>
                <a href="mailto:crosseng@uol.com.br">crosseng@uol.com.br</a>
              </InformationItem>
              <InformationItem>
                <ul>
                  <li ref={addRef}>
                    <a href="http://instagram.com/cross_projetos">
                      <Instagram size="30" />
                    </a>
                  </li>
                </ul>
              </InformationItem>
            </InformationLine>
          </InformationArea>
        </TextSection>
      </Wrapper>
      <MapSection ref={mapRef}>
        <MapContainer center={position} zoom={18} scrollWheelZoom={false}>
          <TileLayer
            url="https://api.mapbox.com/styles/v1/edutvares/ckm2g2yn00ycz17p8j29e5lcn/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZWR1dHZhcmVzIiwiYSI6ImNrZ2FiNXVvNzA1Zm0zMG1pMG5scG84ZTQifQ.hqYv9EWu6NuIjykEIQnqDA"
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
          />
          <Marker position={position} icon={icon}>
            <Popup>Sede da CROSS</Popup>
          </Marker>
        </MapContainer>
      </MapSection>
    </Container>
  )
}

export default Contact

const Container = styled.section`
  min-height: 90vh;
  width: 100%;
  position: relative;
`

const Wrapper = styled.div`
  width: 90%;
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;
`

const TextSection = styled.div`
  display: flex;
  width: 50%;
  padding: 100px 0;
  flex-direction: column;
  justify-content: space-between;

  ${media.lessThan("medium")`
    width: 100%;
    padding: 30px 0;
  `}
`

const PageTitle = styled.h1`
  font-size: clamp(3.5rem, 10vw, 5rem);
  font-weight: 700;
  margin-bottom: 100px;

  ${media.lessThan("medium")`
    margin-bottom: 0;
  `}
`

const InformationArea = styled.div``

const InformationLine = styled.div`
  display: flex;
  margin-top: 20px;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`

const InformationItem = styled.div`
  margin-left: 30px;

  ${media.lessThan("medium")`
    margin-left: 0;
    margin-top: 30px;
  `}

  &:nth-child(1) {
    margin-left: 0;
  }

  ul {
    list-style: none;
    display: flex;
  }

  li {
    margin-left: 5px;
  }

  li:nth-child(1) {
    ${media.lessThan("medium")`
      margin-left: 0;
    `}
  }
`

const MapSection = styled.div`
  width: 40%;
  height: 100%;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: inset 5px 5px 20px rgba(0, 0, 0, 0.25);

  ${media.lessThan("medium")`
    position: static;
    width: 100%;
    height: 300px;
  `}

  & > div {
    width: 100%;
    height: 100%;
  }
`
